<template>
  <KCourse loader-class="MBcont" course-id="6" title="Будуарная кукла" :items="items">
    <template v-slot:subtitle>
      <p class='small-text'>Скачать книгу для печати, к сожалению, нельзя (в связи с защитой материала).</p>
    </template>
    <template v-slot:default>
      <div class="MBtextcont">
        <p v-if="isFirstPage" class="nomargin"><a class="a-download" target="_blank" href="/img/files/DrawingBEG.jpg">Скачать
          чертеж</a></p>
        <p v-if="isFirstPage" class="nomargin"><a class="a-download" target="_blank"
                                                  href="https://vk.com/invite/uS2iV6H">Вступить в закрытую группу</a>
        </p>
      </div>
      <KPdf :src="getPdfSrc"/>
    </template>
  </KCourse>
</template>

<script>
import KCourse from "../../Widgets/KCourse";
import KPdf from "../../Widgets/KPdf";
import md5 from 'md5'

export default {
  name: "Bjd",
  components: {
    KPdf,
    KCourse,
  },
  computed: {
    getPdfSrc() {// eslint-disable-line
      let path = this.$route.path
      let lastPathSegment = path.substring(path.lastIndexOf('/') + 1)
      let fileName = "BB" + lastPathSegment + md5("BB_" + lastPathSegment)
      let fullPath = "/files/BeginnersBook/" + fileName + ".pdf"

      if (this.doesFileExist(fullPath)) {
        return fullPath
      } else {
        this.$router.push("/404")// eslint-disable-line
      }
    },
    isFirstPage() {
      return this.getLastPathSegment() == '1'
    }
  },
  methods: {
    doesFileExist(urlToFile) {
      var xhr = new XMLHttpRequest();
      xhr.open('HEAD', urlToFile, false);
      xhr.send();

      if (xhr.status == "404") {
        return false;
      } else {
        return true;
      }
    },
    getLastPathSegment() {
      let path = this.$route.path
      return path.substring(path.lastIndexOf('/') + 1)
    },

  },
  data() {
    return {
      items: [
        {chapter_id: 51, title: "Создаем основу", url: "/mycourses/beginner/1"},
        {chapter_id: 52, title: "Работаем с основой", url: "/mycourses/beginner/2"},
        {chapter_id: 53, title: "Лепим лицо", url: "/mycourses/beginner/3"},
        {chapter_id: 54, title: "Делаем шарниры", url: "/mycourses/beginner/4"},
        {chapter_id: 55, title: "Перемычки, сборка", url: "/mycourses/beginner/5"},
        {chapter_id: 56, title: "Грунтуем, красим", url: "/mycourses/beginner/6"},
        {chapter_id: 57, title: "Расписываем", url: "/mycourses/beginner/7"},
        {chapter_id: 58, title: "Шьем тело", url: "/mycourses/beginner/8"},
        {chapter_id: 59, title: "Делаем парик", url: "/mycourses/beginner/9"},
        {chapter_id: 60, title: "Костюм. Ч1", url: "/mycourses/beginner/10"},
        {chapter_id: 61, title: "Костюм. Ч2", url: "/mycourses/beginner/11"},
      ],
    }
  }
}
</script>